<!-- 人员管理 -->
<template>
	<div class="doctor_management">
	<el-row class="bread_crumbs">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/PersonnelManagement' }">组织结构管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/PersonnelManagement' }">人员列表</el-breadcrumb-item>
		</el-breadcrumb>
	</el-row>
	<div class="public_bg">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="100px" class="demo-form-inline">
								<el-form-item label-width="60px" label="账号:" prop="account">
									<el-input clearable v-model="formInline.account" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="医院科室:" prop="hospitalDepartmentsList">
									<el-cascader  v-model="formInline.hospitalDepartmentsList" clearable style="width:100%" placeholder="请选择" filterable
										:props="{ value: 'no', label: 'label'}" :options="HospitalDepartmentOptions">
									</el-cascader>
								</el-form-item>
								<el-form-item label="姓名:" prop="fullName">
									<el-input clearable v-model="formInline.fullName" placeholder="请输入"></el-input>
								</el-form-item>
								<div></div>
								<el-form-item  label-width="60px" label="角色:" prop="roleId">
									<el-select filterable  style="width:100%"  v-model="formInline.roleId" placeholder="请选择">
										<el-option
										v-for="item in RoleSelectOptions"
										:key="item.no"
										:label="item.label"
										:value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="是否为首选：" prop="preferenceFlag">
									<el-select filterable  style="width:100%"  v-model="formInline.preferenceFlag" placeholder="请选择">
										<el-option
										v-for="item in preferenceOptions"
										:key="item.no"
										:label="item.label"
										:value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="querys">查询</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button icon="el-icon-plus" type="primary" @click="NewAdd('新增',{})">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}" :data="UserPageData" style="width: 100%">
								<el-table-column
								  label="序号"
								  type="index"
								  width="50"
								  align="left">
								</el-table-column>
								<el-table-column align="left" prop="fullName" label="姓名">
								</el-table-column>
								<el-table-column align="left" prop="account" label="账号">
								</el-table-column>
								<el-table-column align="left" prop="relationTypeName" label="账号类型">
								</el-table-column>
								<el-table-column align="left" prop="hospitalName" label="医院">
								</el-table-column>
								<el-table-column align="left" prop="departmentsName" label="科室">
								</el-table-column>
								<el-table-column align="left" prop="roleName" label="角色">
								</el-table-column>
								<el-table-column align="left" prop="preferenceFlagName" width="150px" label="是否为首选项">
								    <template slot-scope="scope">
										<el-badge is-dot v-if="scope.row.preferenceFlagName=='是'" type="success" style="padding-top: 8px;"></el-badge>
										<el-badge is-dot v-if="scope.row.preferenceFlagName=='否'" type="warning" style="padding-top: 8px;"></el-badge>
										{{scope.row.preferenceFlagName}}
									</template>
								</el-table-column>
								<el-table-column label="操作" width="400px">
									<template slot-scope="scope">
										<el-button type="text" @click="ModifyPreferences(scope.row)">修改首选项</el-button>
										<el-button type="text" :class="scope.row.status!=='200000' ? 'infos' :'primarys'" @click="NewAdd('变更',scope.row)">变更账号</el-button>
									  <el-button type="text" :class="scope.row.status=='200000' ? 'dangers' :'primarys'" @click="stops('状态',scope.row)">{{scope.row.status=='200000' ? '停用' :'启用'}}</el-button>
										<el-button type="text" @click="Resetpwd(scope.row.userId)">重制密码</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<el-dialog :title="title" :visible.sync="DialogVisible" width="40%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<template v-if="title=='新增账号'">
					<el-form-item label="账号类型:" prop="relationType">
						<el-select @change="changes" filterable ref="selection" style="width:100%"  v-model="ruleForm.relationType" placeholder="请选择">
							<el-option
							  v-for="item in userTypeOptions"
							  :key="item.no"
							  :label="item.label"
							  :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="changesFlag=='2'" label="医院科室:" prop="hospitalModel">
						<el-cascader  v-model="ruleForm.hospitalModel" clearable style="width:100%" placeholder="请选择" filterable
							 :props="{ value: 'no', label: 'label'}" :options="HospitalDepartmentOptions">
						</el-cascader>
					</el-form-item>
					<el-form-item v-if="changesFlag=='3'" label="厂家:" prop="manufacturerId">
						<el-select filterable  style="width:100%"  v-model="ruleForm.manufacturerId" placeholder="请选择">
							<el-option
							  v-for="item in ManufactureSelectOptions"
							  :key="item.no"
							  :label="item.label"
							  :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="角色:" prop="roleId">
						<el-select filterable  style="width:100%"  v-model="ruleForm.roleId" placeholder="请选择">
							<el-option
							  v-for="item in RoleSelectOptions"
							  :key="item.no"
							  :label="item.label"
							  :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名:" prop="fullName">
						<el-input v-model="ruleForm.fullName" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="账号:" prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入"></el-input>
					</el-form-item>
				</template>
				<template v-if="title=='变更账号'">
					<el-form-item label="账号类型:" prop="relationType">
						<el-select  :disabled=true filterable ref="selection" style="width:100%"  v-model="ruleForm.relationType" placeholder="请选择">
							<el-option
							  v-for="item in userTypeOptions"
							  :key="item.no"
							  :label="item.label"
							  :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名:" prop="fullName">
						<el-input v-model="ruleForm.fullName"></el-input>
					</el-form-item>
					<el-form-item label="手机号:"  prop="account">
						<el-input :disabled=true v-model="ruleForm.account"></el-input>
					</el-form-item>
					<el-form-item v-if="ruleForm.relationType=='200029'" label="医院科室" prop="hospitalModel">
						<el-cascader :disabled=true  v-model="ruleForm.hospitalModel" clearable style="width:100%" placeholder="请选择" filterable
							 :props="{ value: 'no', label: 'label'}" :options="HospitalDepartmentOptions">
						</el-cascader>
					</el-form-item>
					<el-form-item v-if="ruleForm.relationType=='200030'" label="厂家:" prop="manufacturerId">
						<el-select :disabled=true filterable  style="width:100%"  v-model="ruleForm.manufacturerId" placeholder="请选择">
							<el-option
							  v-for="item in ManufactureSelectOptions"
							  :key="item.no"
							  :label="item.label"
							  :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
                </template>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="DialogVisibleSave">确 认</el-button>
			</span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
	import pwdCrypto from '../../utils/index'
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//账号改变
			changes(val){
				if(val=="200029"){
					//医生
					this.changesFlag="2";
				}else{
					//工程师
					this.changesFlag="3";
				}
			},
			//医院科室接口
			async QueryByHospitalDepartment(){
				let obj={
                   menuId: this.menuCode
				}
			    let res = await this.$Http.ByHospitalDepartment(obj);
				if (res.code == '200') {
					this.HospitalDepartmentOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//账号类型接口
			async QueryUserType(){
			    let res = await this.$Http.DepartmentType({parentCode:"100009"});
				if (res.code == '200') {
					this.userTypeOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//厂家下拉
			async QueryManufactureSelect(){
				let obj={
					parentCode:"100010",
					menuId: this.menuCode,
				}
			    let res = await this.$Http.ManufactureSelect(obj);
				if (res.code == '200') {
					this.ManufactureSelectOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//角色下拉
			async QueryRoleSelect(){
			    let res = await this.$Http.RoleSelect({parentCode:"100010"});
				if (res.code == '200') {
					this.RoleSelectOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//是否为首选项
			async Querypreference(){
			    let res = await this.$Http.DepartmentType({parentCode:"100006"});
				if (res.code == '200') {
					this.preferenceOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//分页接口
			async QueryUserQueryPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					account: this.formInline.account,
					fullName:this.formInline.fullName,
					hospitalId:this.formInline.hospitalDepartmentsList.length>=1 ? this.formInline.hospitalDepartmentsList[0] : "",
					departmentsId:this.formInline.hospitalDepartmentsList.length>=2 ? this.formInline.hospitalDepartmentsList[1] : "",
					roleId: this.formInline.roleId,
					preferenceFlag:this.formInline.preferenceFlag,
					menuId: this.menuCode
				}
				let res = await this.$Http.UserQueryPageInfo(info)
				if (res.code == '200') {
					this.UserPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.UserPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//新增接口
			async QueryUserInsertBy() {
				let info = {
					userInfoBasicInsertDto:{
						account: this.ruleForm.account,
						password: pwdCrypto.encrypto('123456'),
						fullName: this.ruleForm.fullName,
					},
					orgUserRelationInsertDto:{
						relationType: this.ruleForm.relationType,
						hospitalId:  this.ruleForm.hospitalModel.length>=1 ? this.ruleForm.hospitalModel[0] : "",
						departmentsId: this.ruleForm.hospitalModel.length>=2 ? this.ruleForm.hospitalModel[1] : "",
						manufacturerId: this.ruleForm.manufacturerId,
						roleId:this.ruleForm.roleId
					}
				}
				let res = await this.$Http.UserInsertByInfo(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryUserQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//重置密码
			async Resetpwd(id){
				let info = {
					userId: id,
					password: pwdCrypto.encrypto('123456')
				}
				let res = await this.$Http.Resetpwd(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//修改接口
			async QueryUserUpdateBy() {
				let info = {
					userId:this.ruleForm.userId,
					account: this.ruleForm.account,
					fullName: this.ruleForm.fullName,
					status:this.ruleForm.status
				}
				let res = await this.$Http.UserUpdateByInfo(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryUserQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//首选项接口
			async QueryOrgUserRelationUpdateByInfo(item) {
				let info = {
					relationId: item.relationId,
					userId: item.userId,
					//preferenceFlag: "200000"
				}
				let res = await this.$Http.OrgUserRelationUpdateByInfo(info);
				if (res.code == '200') {
					this.QueryUserQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//修改首选项
			ModifyPreferences(item){
                this.QueryOrgUserRelationUpdateByInfo(item);
			},
			//保存
			DialogVisibleSave() {
				this.$refs["ruleForm"].validate((valid) => {
				  if (valid) {
					  this.title == "新增账号" ? this.QueryUserInsertBy() : this.QueryUserUpdateBy();
				  } else {
					return false;
				  }
				});
			},
			//分页组件
			pages(current, size) {
				this.QueryUserQueryPage(current, size);
			},
			//停用
			async QueryUserUpdateBys() {
				let info = {
					userId:this.ruleForm.userId,
					relationId: this.ruleForm.relationId,
					status:this.ruleForm.status
				}
				let res = await this.$Http.OrgUserRelationUpdateByInfos(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryUserQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			stops(val, item){
				this.ruleForm = JSON.parse(JSON.stringify(item));
				item.status == "200000" ? this.ruleForm.status = "200001" : this.ruleForm.status = "200000";
                this.QueryUserUpdateBys();
			},
			//编辑新增
			NewAdd(val, item) {
				this.ruleForm = JSON.parse(JSON.stringify(item));
				this.ruleForm.hospitalModel=[item.hospitalId,item.departmentsId];
				
				if (val == "状态") {
					item.status == "200000" ? this.ruleForm.status = "200001" : this.ruleForm.status = "200000";
					this.QueryUserUpdateBy();
				} else {
					if (item.status == "200001") {
						this.$message.error("已停用,不可变更");
						return false;
					} else {
						this.DialogVisible = true;
						this.title = `${val}账号`;
						this.status = item.status;
					}
                }
			},
			// 搜索
			querys() {
				this.QueryUserQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		data() {
			return {
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				changesFlag:"1",
				preferenceOptions:[],//是否为首选项
				RoleSelectOptions:[],//角色
				ManufactureSelectOptions:[],//厂家数据
				userTypeOptions:[],//账号类型数据
				HospitalDepartmentOptions:[],//医院科室数据
				ruleForm: {},
				rules: {
					relationType: [
						{ required: true, message: '请选择账号类型', trigger: 'change' }
					],
					hospitalModel:[
						{ required: true, message: '请选择医院科室', trigger: 'change' }
					],
					manufacturerId:[
						{ required: true, message: '请选择厂家', trigger: 'change' }
					],
					roleId:[
						{ required: true, message: '请选择角色', trigger: 'change' }
					],
					fullName: [
						{ required: true, message: '请填写姓名', trigger: 'blur' }
					],
					account: [
						{ required: true, message: '请填写账号', trigger: 'blur' }
					]
				},
				DialogVisible: false,
				//title
				title: "",
				//form表单查询
				formInline: {
					hospitalDepartmentsList:[],//医院科室
				},
				//table列表
				UserPageData: [],
				//总条数
				total: 0,
			}
		},
		created() {},
		mounted() {
			//分页
			this.QueryUserQueryPage();
			//医院科室
			this.QueryByHospitalDepartment();
			//账号类型
			this.QueryUserType();
			//厂家
			this.QueryManufactureSelect();
			//角色
			this.QueryRoleSelect();
			//是否为首选项
			this.Querypreference();
		}
	}
</script>

<style scoped>
</style>
